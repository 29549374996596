import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useDarkModeManager } from '../../contexts/LocalStorage'

const pulse = keyframes`
  0% { transform: scale(1); }
  60% { transform: scale(1.1); }
  100% { transform: scale(1); }
`

const Wrapper = styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.fill && !props.height
      ? css`
          height: 100vh;
        `
      : css`
          height: 180px;
        `}
`

const AnimatedImg = styled.div`
  animation: ${pulse} 800ms linear infinite;
  & > * {
    width: 120px;
  }
`

const LocalLoader = ({ fill }) => {
  const [darkMode] = useDarkModeManager()

  return (
    <Wrapper fill={fill}>
      <AnimatedImg>
        {darkMode ? (
          <svg viewBox="0 0 2262.735 770" color="text" width="20" xmlns="http://www.w3.org/2000/svg">
            <g data-name="Group 10257">
              <g data-name="Group 10255" transform="translate(0 53)">
                <circle
                  data-name="Ellipse 11"
                  cx="42.23"
                  cy="42.23"
                  transform="translate(0 331.063)"
                  fill="#f0f0f2"
                  r="42.23"
                />
                <path
                  data-name="Path 85"
                  d="M378.117 232.833a45.776 45.776 0 0 1-62.987-3.429l-100.622-106.1a102.256 102.256 0 0 0-21.921-17.616 90.892 90.892 0 0 0-54.579-13.363c-.525 0-1.024.176-1.55.176-66.565 6.155-109.682 73.42-94.967 138.784 3.253 14.212 9.058 27.376 17.967 38.338l-3.6-4.1A162.881 162.881 0 0 1 12.04 151.712C13.24 73.836 75.026 7.272 152.551.612a153.679 153.679 0 0 1 128 50.124L382.726 168.15a45.658 45.658 0 0 1-4.609 64.683Z"
                  fill="#fff"
                />
                <path
                  data-name="Path 86"
                  d="M295.612 476.195a44.7 44.7 0 0 1-63.137-4.981L59.457 269.62c-8.909-10.962-14.715-24.126-17.967-38.338-14.715-65.364 27.877-132.629 94.441-138.784.527 0 1.552-.177 2.077-.177a90.89 90.89 0 0 1 54.579 13.364c-39.364 0-70.844 31.981-70.844 71.345 0 19.693 6.857 36.459 20.195 51.173l158.654 184.829a44.758 44.758 0 0 1-4.98 63.163Z"
                  fill="#f0f0f2"
                />
                <path
                  data-name="Path 88"
                  d="M106.352 428.498a45.747 45.747 0 0 1 62.962 3.928l99.745 106.9a103.12 103.12 0 0 0 21.77 17.818 91.1 91.1 0 0 0 54.479 13.787c.526 0 1.026-.175 1.551-.175 66.615-5.6 110.283-72.519 96.094-138.007-3.128-14.241-8.833-27.452-17.666-38.489l3.578 4.156a162.795 162.795 0 0 1 42.892 114.135c-1.828 77.876-64.164 143.939-141.738 149.969-50.849 4.055-91.74-13.337-127.6-51.173l-101.2-118.216a45.647 45.647 0 0 1 5.133-64.633Z"
                  fill="#fff"
                />
                <path
                  data-name="Path 89"
                  d="M190.811 185.812a44.642 44.642 0 0 1 63.111 5.48l171.366 202.97c8.833 11.037 14.539 24.248 17.667 38.489 14.189 65.489-28.952 132.4-95.568 138.033-.526-.026-1.551.148-2.077.148a91.1 91.1 0 0 1-54.478-13.787 70.988 70.988 0 0 0 71.419-70.794c.177-19.693-6.554-36.509-19.768-51.323L185.331 248.923a44.745 44.745 0 0 1 5.48-63.111Z"
                  fill="#f0f0f2"
                />
              </g>
              <text transform="translate(646.807 606)" fill="#fff" fontSize="642" fontFamily="Noir-Pro Regular">
                <tspan x="0" y="0">
                  SANS
                </tspan>
              </text>
            </g>
          </svg>
        ) : (
          <svg viewBox="0 0 2262.735 770" color="text" width="20" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="b" x1="1" y1=".433" x2=".07" y2=".5" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#19bdfa" />
                <stop offset="1" stopColor="#232cfc" />
              </linearGradient>
              <linearGradient id="a" x1=".042" y1=".725" x2=".64" y2=".119" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#232cfc" />
                <stop offset="1" stopColor="#19bdfa" />
              </linearGradient>
              <linearGradient id="c" x1=".698" y1=".719" x2=".242" y2="-.17" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#1b9bfa" />
                <stop offset="1" stopColor="#232cfc" />
              </linearGradient>
              <linearGradient id="d" x1=".969" y1=".294" x2="0" y2=".242" href="#a" />
              <linearGradient id="e" x1="1.147" y1=".376" x2=".114" y2=".435" href="#a" />
            </defs>
            <g data-name="Group 10256">
              <g data-name="Group 10254" transform="translate(0 51)">
                <circle
                  data-name="Ellipse 13"
                  cx="42.611"
                  cy="42.611"
                  transform="translate(0 334.056)"
                  fill="url(#b)"
                  r="42.611"
                />
                <path
                  data-name="Path 102"
                  d="M1033.256 1168.175a46.1 46.1 0 0 1-63.537-3.444l-101.542-107.079a103.853 103.853 0 0 0-22.109-17.764A91.65 91.65 0 0 0 791 1026.419c-.535 0-1.044.169-1.553.169-67.178 6.213-110.691 74.071-95.838 140.034 3.277 14.344 9.148 27.643 18.129 38.687l-3.641-4.151a164.118 164.118 0 0 1-44.197-114.819c1.214-78.582 63.536-145.761 141.756-152.482a155.12 155.12 0 0 1 129.185 50.6l103.071 118.458a46.036 46.036 0 0 1-4.656 65.26Z"
                  transform="translate(-651.746 -933.245)"
                  fill="url(#a)"
                />
                <path
                  data-name="Path 103"
                  d="M924.348 1324.664a45.113 45.113 0 0 1-63.729-5l-174.568-203.423c-8.981-11.044-14.852-24.343-18.129-38.687-14.854-65.962 28.127-133.82 95.3-140.033.51 0 1.552-.169 2.087-.169a91.65 91.65 0 0 1 55.066 13.469c-39.7 0-71.473 32.278-71.473 72.007 0 19.853 6.893 36.766 20.362 51.62l160.08 186.485a45.188 45.188 0 0 1-4.996 63.731Z"
                  transform="translate(-626.058 -844.179)"
                  fill="url(#c)"
                />
                <path
                  data-name="Path 105"
                  d="M683.467 984.173a46.139 46.139 0 0 1 63.513 3.978l100.668 107.877a104.618 104.618 0 0 0 21.964 17.96 91.631 91.631 0 0 0 54.968 13.906c.51 0 1.045-.171 1.554-.146 67.226-5.679 111.274-73.2 96.978-139.281-3.178-14.343-8.93-27.69-17.837-38.831l3.592 4.174a164.267 164.267 0 0 1 43.3 115.206c-1.844 78.56-64.726 145.2-143.018 151.319-51.306 4.076-92.587-13.471-128.748-51.645l-102.127-119.282a46.076 46.076 0 0 1 5.193-65.235Z"
                  transform="translate(-576.151 -551.813)"
                  fill="url(#d)"
                />
                <path
                  data-name="Path 106"
                  d="M686.78 951.164a45.1 45.1 0 0 1 63.681 5.534l172.918 204.807c8.907 11.14 14.658 24.489 17.837 38.831 14.3 66.085-29.219 133.6-96.446 139.281-.509 0-1.578.146-2.087.146a91.622 91.622 0 0 1-54.968-13.907c39.7.315 71.74-31.72 72.055-71.425.169-19.852-6.6-36.84-19.949-51.791l-158.552-187.794a45.159 45.159 0 0 1 5.511-63.682Z"
                  transform="translate(-494.252 -763.68)"
                  fill="url(#e)"
                />
              </g>
              <text transform="translate(639.735 606)" fill="#24255b" fontSize="642" fontFamily="Noir-Pro Regular">
                <tspan x="0" y="0">
                  SANS
                </tspan>
              </text>
            </g>
          </svg>
        )}
      </AnimatedImg>
    </Wrapper>
  )
}

export default LocalLoader

import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Flex } from 'rebass'
import { RowFixed } from '../Row'

import { BasicLink } from '../Link'
import { useMedia } from 'react-use'

const TitleWrapper = styled.div`
  text-decoration: none;
  z-index: 10;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`

const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.6)};
  color: ${({ theme }) => theme.white};
  display: flex;
  margin-left: 12px;
  :hover {
    opacity: 1;
  }
`

export default function Title() {
  const history = useHistory()
  const below1080 = useMedia('(max-width: 1080px)')

  return (
    <TitleWrapper>
      <Flex alignItems="center" style={{ justifyContent: 'space-between' }}>
        <RowFixed>
          <div onClick={() => history.push('/')}>
            <svg viewBox="0 0 2300 770" color="text" width="80px" xmlns="http://www.w3.org/2000/svg">
              <g id="Group_10257" data-name="Group 10257" transform="translate(-377.265 4218)">
                <g id="Group_10255" data-name="Group 10255" transform="translate(377.265 -4165)">
                  <ellipse
                    id="Ellipse_11"
                    data-name="Ellipse 11"
                    cx="42.23"
                    cy="42.23"
                    rx="42.23"
                    ry="42.23"
                    transform="translate(0 331.063)"
                    fill="#f0f0f2"
                  />
                  <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M671.483,975.113"
                    transform="translate(-478.272 -726.691)"
                    fill="none"
                    stroke="#707070"
                    strokeMiterlimit="10"
                    strokeWidth="0.25"
                  />
                  <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M669.437,974.3"
                    transform="translate(-527.424 -746.151)"
                    fill="none"
                    stroke="#707070"
                    strokeMiterlimit="10"
                    strokeWidth="0.25"
                  />
                  <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M666.783,971.166"
                    transform="translate(-591.185 -821.514)"
                    fill="none"
                    stroke="#707070"
                    strokeMiterlimit="10"
                    strokeWidth="0.25"
                  />
                  <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M1030.344,1198.02a45.776,45.776,0,0,1-62.987-3.429l-100.622-106.1a102.256,102.256,0,0,0-21.921-17.616,90.892,90.892,0,0,0-54.579-13.363c-.525,0-1.024.176-1.55.176-66.565,6.155-109.682,73.42-94.967,138.784,3.253,14.212,9.058,27.376,17.967,38.338l-3.6-4.1a162.881,162.881,0,0,1-43.818-113.811c1.2-77.876,62.986-144.44,140.511-151.1a153.679,153.679,0,0,1,128,50.124l102.175,117.414A45.658,45.658,0,0,1,1030.344,1198.02Z"
                    transform="translate(-652.227 -965.187)"
                    fill="#fff"
                  />
                  <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M922.335,1353.01a44.7,44.7,0,0,1-63.137-4.981L686.18,1146.435c-8.909-10.962-14.715-24.126-17.967-38.338-14.715-65.364,27.877-132.629,94.441-138.784.527,0,1.552-.177,2.077-.177A90.89,90.89,0,0,1,819.31,982.5c-39.364,0-70.844,31.981-70.844,71.345,0,19.693,6.857,36.459,20.195,51.173l158.654,184.829A44.758,44.758,0,0,1,922.335,1353.01Z"
                    transform="translate(-626.723 -876.815)"
                    fill="#f0f0f2"
                  />
                  <path
                    id="Path_87"
                    data-name="Path 87"
                    d="M680.073,985.73"
                    transform="translate(-271.903 -471.625)"
                    fill="none"
                    stroke="#707070"
                    strokeMiterlimit="10"
                    strokeWidth="0.25"
                  />
                  <path
                    id="Path_88"
                    data-name="Path 88"
                    d="M683.546,1015.176a45.747,45.747,0,0,1,62.962,3.928l99.745,106.9a103.12,103.12,0,0,0,21.77,17.818,91.1,91.1,0,0,0,54.479,13.787c.526,0,1.026-.175,1.551-.175,66.615-5.6,110.283-72.519,96.094-138.007-3.128-14.241-8.833-27.452-17.666-38.489l3.578,4.156a162.795,162.795,0,0,1,42.892,114.135c-1.828,77.876-64.164,143.939-141.738,149.969-50.849,4.055-91.74-13.337-127.6-51.173l-101.2-118.216A45.647,45.647,0,0,1,683.546,1015.176Z"
                    transform="translate(-577.194 -586.678)"
                    fill="#fff"
                  />
                  <path
                    id="Path_89"
                    data-name="Path 89"
                    d="M686.748,982.75a44.642,44.642,0,0,1,63.111,5.48L921.225,1191.2c8.833,11.037,14.539,24.248,17.667,38.489,14.189,65.489-28.952,132.4-95.568,138.033-.526-.026-1.551.148-2.077.148a91.1,91.1,0,0,1-54.478-13.787,70.988,70.988,0,0,0,71.419-70.794c.177-19.693-6.554-36.509-19.768-51.323L681.268,1045.861A44.745,44.745,0,0,1,686.748,982.75Z"
                    transform="translate(-495.937 -796.938)"
                    fill="#f0f0f2"
                  />
                </g>
                <text
                  id="SANS"
                  transform="translate(1024.072 -3612)"
                  fill="#fff"
                  fontSize="642"
                  fontFamily="Noir-Pro Regular"
                >
                  <tspan x="0" y="0">
                    SANS
                  </tspan>
                </text>
              </g>
            </svg>
          </div>
        </RowFixed>
        {below1080 && (
          <RowFixed style={{ alignItems: 'flex-end' }}>
            <BasicLink to="/home">
              <Option activeText={history.location.pathname === '/home' ?? undefined}>Overview</Option>
            </BasicLink>
            <BasicLink to="/tokens">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'tokens' ||
                    history.location.pathname.split('/')[1] === 'token') ??
                  undefined
                }
              >
                Tokens
              </Option>
            </BasicLink>
            <BasicLink to="/pairs">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'pairs' ||
                    history.location.pathname.split('/')[1] === 'pair') ??
                  undefined
                }
              >
                Pairs
              </Option>
            </BasicLink>

            <BasicLink to="/accounts">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'accounts' ||
                    history.location.pathname.split('/')[1] === 'account') ??
                  undefined
                }
              >
                Accounts
              </Option>
            </BasicLink>
          </RowFixed>
        )}
      </Flex>
    </TitleWrapper>
  )
}
